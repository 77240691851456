import { useEffect, useState } from 'react'

/**
 * Handler to listen for a specific media query match on the current view
 * This will bind the media event and update the return based on the matched property when the
 * window size changes.
 */
export const useMediaQuery = (mediaQuery: string): boolean => {
	const [mediaMatched, setMediaMatched] = useState<boolean>(false)

	useEffect(() => {
		function handleMediaChange(media: MediaQueryListEvent) {
			setMediaMatched(media.matches)
		}
		const mediaQueryList = window?.matchMedia(mediaQuery)
		setMediaMatched(mediaQueryList.matches)
		mediaQueryList.addEventListener('change', handleMediaChange)
		return () => {
			mediaQueryList.removeEventListener('change', handleMediaChange)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return mediaMatched
}
