import { getDomainFromLocale } from '~/lib/i18n/locale'

interface SiteLinksProps {
	locale: string
}

export const SiteLinks = ({ locale }: SiteLinksProps) => {
	const baseSearchUrl = `https://${getDomainFromLocale(locale)}/${locale}/`
	return (
		<>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{
						"@context": "https://schema.org",
						"@type": "WebSite",
						"url": "${baseSearchUrl}",
						"potentialAction": {
							"@type": "SearchAction",
							"target": {
								"@type": "EntryPoint",
								"urlTemplate": "${baseSearchUrl}search?q={search_term_string}"
							},
							"query-input": "required name=search_term_string"
						}
					}`,
				}}
			/>
		</>
	)
}
