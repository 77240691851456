'use client'

import type { PageContentData } from '~/lib/types/coremedia.interface'
import ContentSlot from '~/components/cms/ContentSlot/ContentSlot'
import { useLocale } from '~/components/hooks/useLocale'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { getListOfItemsInPlacement, getListOfItemsInPlacementBasedOnSelectionRules } from '~/lib/content'
import { ensureArray } from '~/types/strict-null-helpers'
import { cacheDataToProps } from '~/lib/server/caching'
import type { CacheData } from '~/lib/client-server/caching'
import { ContentSource } from '~/lib/client-server/cms/types'

import styles from './CoreMediaPlacements.module.scss'

interface CoreMediaPlacementsProps {
	cacheData?: CacheData
	pageContent: PageContentData
	/** Extended classname so the parent page can apply additional styling to the wrapper */
	className?: string
}

/**
 * This is being depreciated. Please use the `CoreMediaPlacements` component
 * @returns collection of CM Modules
 */
export default function CoreMediaPlacements({ cacheData, pageContent, className = '' }: CoreMediaPlacementsProps) {
	const placements = pageContent?.placements ?? []
	// Validate that content in placements exists by looking for our various content lists in `getListOfItemsInPlacement`
	const validPlacements = placements.filter((p) => ensureArray(getListOfItemsInPlacement(p)).length > 0)
	const { user: customer } = useSession()
	const locale = useLocale()

	if (!validPlacements?.length) {
		// Don't render anything if there are no placements
		return null
	}

	return (
		<section
			{...(cacheData ? cacheDataToProps(cacheData) : {})}
			className={`${styles.placement__wrapper} ${className}`}
		>
			{validPlacements.map((placement, placementIndex) => {
				return (
					<div key={`${placement.name}_${placementIndex}`} className={placement.name}>
						{getListOfItemsInPlacementBasedOnSelectionRules(placement, { customer })
							.filter((p) => !!p?.name)
							.map((item, index) => (
								<ContentSlot
									key={`${placement.name}_${index}_${item.id}`}
									placementId={placement.name}
									defaultModule={{ source: ContentSource.COREMEDIA, data: item }}
									locale={locale}
									index={placementIndex}
								/>
							))}
					</div>
				)
			})}
		</section>
	)
}
