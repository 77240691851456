'use client'

import dynamic from 'next/dynamic'
import LazyLoad from 'react-lazy-load'
import { useCallback, useMemo } from 'react'
import CoreMediaPlacements from '~/components/cms/legacy/CoreMediaPlacements'
import { useAnalyticsSetup } from '~/components/hooks/useAnalytics'
import { useLocale } from '~/components/hooks/useLocale'
import type { Analytics } from '~/lib/analytics'
import { pageHasFeaturedProducts } from '~/lib/client-server/cms/sources/coremedia/shared/featured-products'
import type { PageContentData } from '~/lib/types/coremedia.interface'
import { PersonalizationProvider } from '~/components/providers/PersonalizationProvider/PersonalizationProvider'
import { pageHasHotspots } from '~/lib/client-server/cms/sources/coremedia/shared/hotspots'
import { pageHasContentCarousel } from '~/lib/client-server/cms/sources/coremedia/shared/collection'
import styles from './Home.module.scss'
import { cacheDataToProps } from '~/lib/server/caching'
import type { CacheData } from '~/lib/client-server/caching'
import { SiteLinks } from '~/components/search/SiteLinks'

const CuralateHome = dynamic(() => import('./CuralateHome/CuralateHome'), { ssr: false })

export interface HomeProps {
	cacheData?: CacheData
	pageContent: PageContentData
	locale: string
}

function Home({ cacheData, pageContent }: HomeProps) {
	const locale = useLocale()
	const hasFeaturedProducts = useMemo(() => pageHasFeaturedProducts(pageContent), [pageContent])

	/* -------------------- Analytics -------------------- */
	const beforePageView = useCallback(
		(analytics: Analytics) => {
			analytics.setPageData({
				featured_products: {
					state: hasFeaturedProducts ? 'viewed' : null,
				},
				hotspots: {
					state: pageHasHotspots(pageContent) ? 'viewed' : null,
				},
				content_carousel: {
					state: pageHasContentCarousel(pageContent) ? 'loaded' : null,
				},
			})
		},
		[pageContent, hasFeaturedProducts],
	)
	useAnalyticsSetup({
		allowPageView: true,
		pageCategory: ['home'],
		page_name: 'home',
		page_type: 'content',
		site_section: 'Home',
		beforePageView,
	})

	return (
		<>
			<SiteLinks locale={locale} />
			<PersonalizationProvider>
				<div className={styles.home} {...(cacheData ? cacheDataToProps(cacheData) : {})}>
					<CoreMediaPlacements pageContent={pageContent} />
				</div>
			</PersonalizationProvider>
			<LazyLoad>
				<CuralateHome locale={locale} />
			</LazyLoad>
		</>
	)
}

export default Home
